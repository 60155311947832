(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/start-page/assets/javascripts/start-page.js') >= 0) return;  svs.modules.push('/modules/start-page/assets/javascripts/start-page.js');

'use strict';

function renderAppPromo() {
  const appPromo = new svs.components.AppPromo({
    storageKey: 'se.svenskaspel.spelbutiken',
    message: "Kom n\xE4ra dina sport- och spelupplevelser.\n          <a\n            rel=\"nofollow\"\n            href=\"https://play.google.com/store/apps/details?id=se.svenskaspel.sportcasino\"\n            target=\"_blank\">\n              Ladda ner Sport & Casino-appen f\xF6r Android h\xE4r.\n          </a>",
    storageExpires: 2628000 
  });
  appPromo.show();
}
let isBottomSheetDoubleClick = false;
const _navigate = path => {
  if (isBottomSheetDoubleClick) {
    return;
  }
  isBottomSheetDoubleClick = true;
  const currentPathEncodedParams = svs.utils.url.currentPathEncodedParams();
  const fullPath = "".concat(path, "?returnUrl=").concat(currentPathEncodedParams);
  isBottomSheetDoubleClick = false;
  location.href = fullPath;
};
const renderBottomsheet = () => {
  const btn = svs.ui.button.partials.button({
    class: 'js-login-button-bottom-sheet',
    focus: true,
    block: true,
    content: 'Logga in',
    id: 'start-page-bottom-sheet-login'
  });
  const content = "<div class=\"f-center padding-xs-bottom-1 padding-xs-h-2 padding-xs-top-2\">".concat(btn, "</div>\n       <div class=\"f-center padding-xs-bottom-2\">\n         <a class=\"js-registration-link-bottom-sheet fc-navy u-text-decoration-underline\" id=\"start-page-bottom-sheet-register\">Bli kund</a>\n       </div>");
  const bottomSheet = new svs.ui.BottomSheet({
    classNames: 'bg-sport-100 bottom-sheet-sport ',
    transparent: true,
    isFixed: true,
    footerContent: content
  });
  bottomSheet.show();
  document.querySelector('.js-login-button-bottom-sheet').addEventListener('click', () => {
    if (svs.core.detect.feature('ft-slim-login')) {
      svs.ui.loader.show();
      bottomSheet.hideSheet();
      const loadAndInitComponent = () => {
        svs.core.module.load('/cl/accountservices-react/react:login-slim', null, () => {
          svs.accountservices_react.login_slim.init();
          svs.ui.loader.hide();
        }, false);
      };
      svs.components.analytics.trackEvent({
        category: 'Navigation',
        action: 'bottom_sheet',
        opt_label: 'logga in'
      }, () => {
        loadAndInitComponent();
      });
    } else {
      svs.components.analytics.trackEvent({
        category: 'Navigation',
        action: 'bottom_sheet',
        opt_label: 'logga in'
      }, () => {
        _navigate(svs.core.urlMapping.get('loginHome'));
      });
    }
  });
  document.querySelector('.js-registration-link-bottom-sheet').addEventListener('click', () => {
    svs.components.analytics.trackEvent({
      category: 'Navigation',
      action: 'bottom_sheet',
      opt_label: 'bli kund'
    }, () => {
      _navigate(svs.core.urlMapping.get('customerRegistrationHome'));
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  renderAppPromo();
  if (svs.core.detect.formfactor.mobile() && !svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    renderBottomsheet();
  }

  if (Modernizr.history && history.state && history.state.type && history.state.type === 'modal') {
    history.back();
  }
});

 })(window);